import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import { sha256 } from 'js-sha256';
import { useLocation } from 'react-router-dom';
import * as InvoicesServices from '../../services/InvoicesServices';
import LinearProgress from '@mui/joy/LinearProgress';


export default function PaymentForm() {

    const[loading,setLoading]=React.useState(null);
    const[order_id,setOrder_id]=React.useState(null);

    // Get the location object
  const location = useLocation();

  // Function to get a URL parameter value by key
  const getUrlParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  // Example: Get the "price" parameter
  let price = getUrlParam('price');
  

  // Remove 'RM' from the price (if present)
  if (price) {
    price = price.replace('RM', '').trim(); // Remove 'RM' and trim any leading/trailing spaces
  }

  const products = getUrlParam('products');

   // API CALL
   const add_one_gkcart = async (details) => {
    try {
      setLoading(true);
      let res = await InvoicesServices.add_one_gkcart(details);
      if(res.data.success == true){
        setOrder_id(res.data.gkcartid)
        setLoading(true);
       }
      setLoading(false);
    } catch (e) {

    }
  };

  React.useEffect(() => {
    add_one_gkcart({"price": price, "products": products, "createdAt": Date.now()});
  },[])

  // Function to parse the 'products' parameter
  const parseProducts = (productsParam) => {
    if (!productsParam) return [];

    // Decode the URL-encoded characters (e.g., %3A becomes :)
    return decodeURIComponent(productsParam);
    
    
  };

  const productDetails = parseProducts(products);

    var cartDetail=order_id
    var cartTotal=price
    var orderId="001" //generate unique next time
    const senangKey="42319-589"

    function handleSenangPayButtonClick(email,name,phone,detail,order_id,amount){

        var hash = sha256.hmac(senangKey,senangKey+detail+amount+order_id)

        var details = 
        {
          'detail': detail,
          'amount': amount,
          'order_id': order_id,
          'name': name,
          'email': email,
          'phone': phone,
          'hash':hash
        };
        
        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        
        fetch('https://app.senangpay.my/payment/600172437305997', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          },
          body: formBody
        }).then(response => {
          // When the page is loaded convert it to text
          return response.text()
        })
        .then(html => {
          // Initialize the DOM parser
          const parser = new DOMParser()
      
          // Parse the text
          const doc = parser.parseFromString(html, "text/html")
      
          // You can now even select part of that html as you would in the regular DOM
          // Example:
          // const docArticle = doc.querySelector('article').innerHTML
          sessionStorage.setItem("paymenthtml", doc);
          console.log(doc)
          const winUrl = URL.createObjectURL(
            new Blob([html], { type: "text/html" })
        );
        
        const win = window.open(
            winUrl,
            "win",
            `width=800,height=400,screenX=200,screenY=200`
        );
          
        })
        .catch(error => {
           console.error('Failed to fetch page: ', error)
        })
      }


      if (loading) {
        return (<CssVarsProvider disableTransitionOnChange>
            <CssBaseline />
            <Box
              component="main"
              sx={{
                height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
                display: 'grid',
                gridTemplateColumns: { xs: 'auto', md: '60% 40%' },
                gridTemplateRows: 'auto 1fr auto',
              }}
            >
              <Stack
                sx={{
                  backgroundColor: 'background.surface',
                  px: { xs: 2, md: 4 },
                  py: 2,
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                }}
              >
              </Stack>
              <Box
                sx={{
                  gridRow: 'span 3',
                  display: { xs: 'none', md: 'flex' },
                  backgroundColor: 'background.level1',
                  backgroundSize: 'cover',
                  backgroundImage:
                    'url("https://www.shenyuncollections.com/cdn/shop/files/ABOUT-US-SY.jpg?v=1675921248&width=3200")',
                }}
              />
              <LinearProgress/>
            </Box>
          </CssVarsProvider>
        );
        }

  else { return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: { xs: '100%', md: '50vw' },
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{ py: 3, display: 'flex', justifyContent: 'space-between' }}
          >
            <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
              <IconButton variant="soft" color="primary" size="sm">
                <BadgeRoundedIcon />
              </IconButton>
              <Typography level="title-lg">Global Renaissance Collections</Typography>
            </Box>
            {/* <ColorSchemeToggle /> */}
          </Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Stack sx={{ gap: 4, mb: 2 }}>
              <Stack sx={{ gap: 1 }}>
                <Typography component="h1" level="h3">
                  Payment Form
                </Typography>
                <Typography level="h6">
                  Order ID: {order_id}
                </Typography>
                <Typography level="h6">
                  Total Amount: RM{price}
                </Typography>
                <Typography level="body-sm">
                  Need help?{' '}
                  <Link href="https://globalrenaissancecollections.com/contact-us" level="title-sm">
                    Contact Us!
                  </Link>
                </Typography>
                <Stack sx={{ gap: 4, mt: 2 }}>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  const formElements = event.currentTarget.elements;
                  const data = {
                    email: formElements.email.value,
                    name: formElements.name.value,
                    phone: formElements.phone.value
                    // persistent: formElements.persistent.checked,
                  };
                  handleSenangPayButtonClick(formElements.email.value,formElements.name.value,formElements.phone.value,cartDetail,orderId,cartTotal)
                //   alert(JSON.stringify(data, null, 2));
                }}
              >
                <FormControl required>
                  <FormLabel>Name</FormLabel>
                  <Input type="name" name="name" />
                </FormControl>
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" name="email" />
                </FormControl>
                <FormControl required>
                  <FormLabel>Phone Number (preferably Whatsapp/SMS)</FormLabel>
                  <Input type="phone" name="phone" />
                </FormControl>
                <Stack sx={{ gap: 4, mt: 2 }}>
                  {/* <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox size="sm" label="Remember me" name="persistent" />
                    <Link level="title-sm" href="#replace-with-a-link">
                      Forgot your password?
                    </Link>
                  </Box> */}
                  <Button type="submit" fullWidth>
                    Click for Online Payment Now
                  </Button>
                </Stack>
              </form>
            </Stack>  
            <Divider
              sx={(theme) => ({
                [theme.getColorSchemeSelector('light')]: {
                  color: { xs: '#FFF', md: 'text.tertiary' },
                },
              })}
            >
              {/* or */}
            </Divider>
            </Stack>
              {/* <Button
                variant="soft"
                color="neutral"
                fullWidth
                // startDecorator={<GoogleIcon />}
              >
                Continue with Manual Bank Transfer
              </Button>
              <Button
                variant="soft"
                color="neutral"
                fullWidth
                // startDecorator={<GoogleIcon />}
              >
                Continue with Manual QR Payment
              </Button>
              <Button
                variant="soft"
                color="neutral"
                fullWidth
                // startDecorator={<GoogleIcon />}
              >
                Continue with Cheque Payment
              </Button> */}
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" sx={{ textAlign: 'center' }}>
              © Global Renaissance Collections {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: '50vw' },
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
          },
        })}
      />
    </CssVarsProvider>
  );}
}