import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ReactDOM from 'react-dom'
import Login from './pages/Auth/Login'
import SignUp from './pages/Auth/SignUp'
import ForgotPassword from './pages/Auth/ForgotPassword'
import Products from './pages/Admin/Products'
import CreateProduct from './pages/Admin/CreateProduct'
import ProductsSales from './pages/Sales/ProductsSales'
import ProductsSalesCardView from './pages/Sales/ProductsSalesCardView'
import InvoiceView from './pages/Admin/InvoiceView'
import ProductsDistributor from './pages/Distributor/ProductsDistributor'
import StocksDistributor from './pages/Distributor/StocksDistributor'
import StocksSales from './pages/Sales/StocksSales'
import Payout from './pages/Admin/PayoutView'
import Payment from "./pages/Auth/Payment";
import PaymentConfirmation from "./pages/Auth/PaymentConfirmation";

import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/" >
          <Route index element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword/> } />
          <Route path="signup" element={<SignUp/> } />
          <Route path="products" element={<Products/> } />
          <Route path="create-product" element={<CreateProduct/> } />
          <Route path="products-sales-card" element={<ProductsSalesCardView/> } />
          <Route path="stocks-sales" element={<StocksSales/> } />
          <Route path="products-distributor" element={<ProductsDistributor/> } />
          <Route path="invoice" element={<InvoiceView/> } />
          <Route path="stocks" element={<StocksDistributor/> } />
          <Route path="payout" element={<Payout/> } />
          <Route path="payment" element={<Payment/> } />
          <Route path="payment-confirmation" element={<PaymentConfirmation/> } />
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Login/>,
//   },
//   {
//     path: "/login",
//     element: <Login/>,
//   },
//   {
//     path: "/forgot-password",
//     element: <ForgotPassword/>,
//   },
//   {
//     path: "signup",
//     element: <SignUp />,
//   },
//   {
//     path: "products",
//     element: <Products />,
//   },
//   {
//     path: "create-product",
//     element: <CreateProduct />,
//   },
//   {
//     path: "products-sales",
//     element: <ProductsSales />,
//   },
//   {
//     path: "products-sales-card",
//     element: <ProductsSalesCardView />,
//   },
//   {
//     path: "invoice",
//     element: <InvoiceView />,
//   },
//   {
//     path: "products-distributor",
//     element: <ProductsDistributor/>,
//   },
//   {
//     path: "stocks-distributor",
//     element: <StocksDistributor/>,
//   },
//   {
//     path: "*",
//     element: <Login/>,
//   },
// ]);


}


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);